<template>
  <div class="contact">
    <div class="aboutOurCompany">
      <div class="title"><h3>المشروعات</h3></div>
      <v-divider></v-divider>
      <v-container>
        <v-row>
          <h4 class="blue--text">
            شركه مياه الشرب والصرف الصحي بأسيوط والوادي الجديد
          </h4>
          <span class="ml-2 mr-2">/</span
          ><span class="gray--text">المشروعات </span>
        </v-row>
        <v-row class="mt-10">
          <v-col class="subClass" cols="12" sm="12" xs="12" lg="9">
            <h3>
              {{ items.title }}
            </h3>
            <hr class="ma-3 fade-in-right center" justify="center" />

            <span class="ql-editor" v-if="items.description" v-html="items.description"> </span>
            <!-- <p class="subtextnews" v-for="subText in TotalText" :key="subText">
              {{ subText }}
            </p> -->
            <v-container class="pa-4 text-center">
              <v-row class="fill-height" align="center" justify="center">
                <v-container class="pa-4 text-center">
                  <v-row class="fill-height" align="center" justify="center">
                    <v-col
                      cols="12"
                      md="4"
                      v-for="(locationImg, index) in items.project_images"
                      :key="index"
                    >
                      <template v-if="index <= 5">
                        <expandable-image
                          :src="
                            'https://backend.ascww.org/api/projects/image/' +
                              locationImg.path
                          "
                          height="225px"
                        >
                        </expandable-image>
                      </template>
                    </v-col>
                  </v-row>
                </v-container>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" sm="12" xs="12" lg="3">
            <LinkX></LinkX>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import LinkX from "../components/link.vue";
export default {
  name: "Link",
  components: {
    LinkX,
  },
  data() {
    return {
      model: null,
      userId: this.$route.params.userId,
    };
  },
  methods: {
    checkID() {
      this.userId = this.$route.params.userId;
    },
  },
  computed: {
    items() {
      // return this.$store.dispatch('findSelectedItem')
      return this.$store.state.projects.find(
        (item) => item.slug == this.userId
      );
    },
    TotalText() {
      var xx = this.items.description;
      const words = xx.split(".");
      return words;
    },
  },
  watch: {
    $route: "checkID",
  },
};
</script>
<style>
h2 {
  width: 600px !important;
}
.subtextnews {
  font-size: 15px;
}
</style>
